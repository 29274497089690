import * as React from "react";
import { useState } from "react";

const palette = {
  gold: "#F6C334",
  lightGreen: "#95C157",
  peach: "#F29886",
  orange: "#E2865C",
  darkGreen: "#457B27",
  cream: "#FFEDA3",
};

type ImageWithLocationData = {
  img: JSX.IntrinsicElements["img"];
  x: number;
  y: number;
};

export default function App() {
  const flowers: Array<ImageWithLocationData> = [];
  const tolerance = 0.1;
  const [clicked, setClicked] = useState(false)

  const isNear = (x: number, y: number) => {
    for (const flower of flowers) {
      const dx = x - flower.x;
      const dy = y - flower.y;
      if (
        (dx < tolerance && dx > -tolerance) ||
        (dy < tolerance && dy > -tolerance)
      ) {
        return true;
      }
    }
    return false;
  };

  for (let i = 0; i < 100; i++) {
    let ySeed = Math.random();
    let x, y;
    let attempts = 0;
    do {
      ySeed = Math.random();
      x = 2 + Math.random() * 96;
      y = 30 + ySeed * 65;
      attempts++;
    } while (isNear(x, y) && attempts < 5);
    if (attempts === 5) {
      continue;
    }

    const rotation = Math.floor(Math.random() * 90);
    const size = 1 + Math.random() * 2 * ySeed;
    const delay = 2 + Math.random() * Math.random() * 30;
    const color = Math.floor(1 + Math.random() * 3);
    const spinDirection = Math.random() > 0.5;

    flowers.push({
      x,
      y,
      img: (
        <img
          key={`flower-${i}`}
          alt="flower"
          src={`flower-${color}.png`}
          style={{
            width: `${size}2vw`,
            top: `${y}vh`,
            left: `${x}vw`,
            rotate: `${rotation}deg`,
            animation: `${spinDirection ? "spin-grow-in" : "spin-grow-in-backwards"} 500ms linear ${delay}s normal backwards`,
            position: "fixed",
          }}
        />
      ),
    });
  }

  return (
    <div className="second">
      <div className="txt">
        <big>
          <big>
            <h1>
              The <span style={{ color: palette.peach }}>Heg</span>
              <span style={{ color: palette.gold }}>Slam</span>
            </h1>
          </big>
        </big>
        <h3 style={{ color: palette.gold }}>Save The Date</h3>
        <small style={{ color: palette.cream }}>
          <p>You have been cordially</p>
          <p>invited to a celebration of</p>
          <p>Fairooz & Liam&#39;s marriage. From:</p>
        </small>
        <h3 style={{ color: palette.gold }}>25/7/2025 - 27/7/2025</h3>
        <p style={{ color: palette.darkGreen }}>Sparkford Hall, Somerset</p>

        <div 
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div 
            className={clicked ? "clickedBtn" : "btn"}
            onClick={() => {
              setClicked(true)
              window.location.href="/hegslam-invite.ics"
            }}
          >
            {clicked ? "See you soon!" : "Add to Calendar"}
          </div>
        </div>
      </div>
      <span className="ground"></span>
      <span className="sun"></span>
      <span className="sun2"></span>
      <img className="birds" alt="birds" src="birds.png" />
      <img className="tent" alt="tent" src="tent.png" />
      <img className="tent2" alt="tent2" src="tent2.png" />
      <img className="sparkford" alt="sparkford" src="sparkford.png" />
      {flowers.map((f) => f.img)}
    </div>
  );
}
